import { IconButton, Stack, Typography } from '@mui/material';
import { OptionsEnum, poolsConfig } from 'features/calculator';
import { COLOR_STROKE, Icons, TipPopover, usePopover } from 'shared';

interface StatusTipProps {
  status: OptionsEnum;
}

const statusInfo = {
  [OptionsEnum.Delegator]: {
    color: '🟢',
    benefits: ['Earn Transaction Fees', 'EcoIP Royalties'],
  },
  [OptionsEnum.Validator]: {
    color: '🟡',
    benefits: ['Earn Transaction Fees', 'EcoIP Royalties'],
  },
  [OptionsEnum.LiteBacker]: {
    color: '🔵',
    benefits: ['EcoIP Royalties', 'Bonus IP Portions', 'IP-Portions Royalty Returns'],
  },
  [OptionsEnum.TurboBacker]: {
    color: '🟣',
    benefits: ['EcoIP Royalties', 'Bonus IP Portions', 'IP-Portions Royalty Returns'],
  },
};

export const StatusTip = ({ status }: StatusTipProps) => {
  const { handleClose, handleOpen, open, anchorEl } = usePopover();

  const statusObj = poolsConfig[status];

  return (
    <>
      <IconButton className="transparent" size="small" onClick={handleOpen}>
        <Icons.QuestionCircle
          sx={{
            color: COLOR_STROKE,
          }}
        />
      </IconButton>
      <TipPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        PaperProps={{
          sx: {
            maxWidth: 240,
            py: 2,
          },
        }}
      >
        <Stack rowGap={1.5}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="inherit" variant="subtitle2">
              Acquire:
            </Typography>
            <Typography color="inherit" variant="subtitle2">
              <b>{(statusObj?.minAmount || 0).toLocaleString('en-US')}</b> CratD2C
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="inherit" variant="subtitle2">
              Status:
            </Typography>
            <Typography color="inherit" variant="subtitle2" className="bold">
              {status} {statusInfo[status].color}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="inherit" variant="subtitle2">
              APR:
            </Typography>
            <Typography color="inherit" variant="subtitle2" className="bold">
              {statusObj.apr}%
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="inherit" variant="subtitle2">
              Re-taking Frequency:
            </Typography>
            <Typography color="inherit" variant="subtitle2" className="bold">
              {statusObj.frequency}
            </Typography>
          </Stack>
          {statusInfo[status].benefits?.map((benefitItem) => (
            <Stack key={benefitItem} direction="row" justifyContent="space-between">
              <Typography color="inherit" variant="subtitle2">
                {benefitItem}:
              </Typography>
              <Typography color="inherit" variant="subtitle2" className="bold">
                YES
              </Typography>
            </Stack>
          ))}
        </Stack>
      </TipPopover>
    </>
  );
};
