/* eslint-disable no-nested-ternary */
import { Controller } from 'react-hook-form';
import { alpha, Box, Button, Card, Stack, TextField, Typography } from '@mui/material';
import { BuyInput } from 'features';
import { BORDER_RADIUS_S, COLOR_RED, COLOR_WH, Icons, ReferralTip } from 'shared';

import { useBuyCoins } from './lib';
import { ReferralBonusText } from './referral-bonus-text';

export const BuyCoins = () => {
  const {
    form,
    validateField,
    currencyField,
    receiveAmount,
    userReferral,
    stableAmount,
    isAuthenticated,
    openConnectModal,
  } = useBuyCoins();

  const {
    handleSubmit,
    register,
    onReferralAddressChange,
    handleMaxClick,
    control,
    isValid,
    isDirty,
    errorMessage,
    errors,
  } = form;
  const { onChangeStableAmount } = validateField;
  const { onCurrencyChange, currency } = currencyField;

  return (
    <Card
      onSubmit={handleSubmit}
      component="form"
      sx={{
        height: '100%',
        padding: { sm: '32px 32px 16px', xs: 2 },
        paddingBottom: 2.5,
      }}
    >
      <Controller
        name="stablesAmount"
        control={control}
        render={({ field }) => {
          return (
            <BuyInput
              {...field}
              onChange={(e) => {
                const val = onChangeStableAmount(e);
                if (val !== false) {
                  field.onChange(val);
                }
              }}
              onCurrencyChange={onCurrencyChange}
              currencyValue={currency}
              stableBalance={stableAmount}
              onMaxClick={handleMaxClick}
              isError={isAuthenticated && !!errors.amount}
            />
          );
        }}
      />

      <Stack pt={3} alignItems="center">
        <Typography className="secondary" variant="h6" mb={0.5}>
          You get
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1.5} mb={3.25}>
          <Icons.Crat />
          <Typography variant="h3" className="no-resize">
            {receiveAmount}
          </Typography>
        </Stack>
        <Stack
          sx={{
            width: '100%',
            maxWidth: {
              sm: '258px',
            },
          }}
        >
          {isAuthenticated ? (
            <>
              <Button disabled={!isValid || !isDirty} type="submit">
                Buy
              </Button>
              <Stack pt={1}>
                {!isValid && !!errorMessage && (
                  <Stack direction="row" justifyContent="center" spacing={2}>
                    <Icons.InfoCircle
                      sx={{
                        color: COLOR_RED,
                      }}
                    />
                    <Typography className="error" variant="body2">
                      {errorMessage}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </>
          ) : (
            <Button onClick={openConnectModal} type="button">
              Connect Wallet
            </Button>
          )}
        </Stack>
      </Stack>

      {isAuthenticated ? (
        userReferral ? (
          <Box
            sx={{
              borderRadius: BORDER_RADIUS_S,
              backgroundColor: alpha(COLOR_WH, 0.06),
              padding: 2,
              mt: 3.25,
            }}
          >
            <TextField
              value={userReferral}
              InputProps={{
                readOnly: true,
                inputProps: { className: 'accent' },
              }}
              label={
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="inherit" className="secondary">
                    Invited by
                  </Typography>
                  <ReferralTip />
                </Stack>
              }
            />
            <ReferralBonusText maxWidth="28.75rem" mx="auto" mt={2} />
          </Box>
        ) : (
          <Box
            sx={{
              borderRadius: BORDER_RADIUS_S,
              backgroundColor: alpha(COLOR_WH, 0.06),
              padding: 2,
              mt: 3.25,
            }}
          >
            <TextField
              {...register('fatherAddress', {
                onChange: onReferralAddressChange,
              })}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="0x76F2a66D8Af418113725e3bAc20BFCb4F92d63b5"
              color={errors.referral ? 'error' : 'primary'}
              label={
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="inherit" className="secondary">
                    Enter Referral address
                  </Typography>
                  <ReferralTip />
                </Stack>
              }
            />
            <ReferralBonusText maxWidth="28.75rem" mx="auto" mt={2} />
          </Box>
        )
      ) : null}
    </Card>
  );
};
