import { Box, Typography } from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, SplittedRows } from 'shared';

import { dataRows } from './constants';

export const WhyParticipate = () => {
  return (
    <Box sx={{ mt: { xs: 0, md: 2.75 } }}>
      <Typography variant="h4" className="accent" sx={{ mb: { xs: 2, md: 3.75 } }}>
        Why Participate?
      </Typography>
      <SplittedRows data={dataRows} titleColor={COLOR_PRIMARY} textColor={COLOR_SECONDARY} mb={0} />
    </Box>
  );
};
