import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { crowdsaleModel } from 'entities/crowdsale';
import {
  appRoutes,
  BORDER_RADIUS_L,
  COLOR_ACCENT,
  COLOR_STROKE_SECONDARY,
  fromDecimals,
  useShallowSelector,
} from 'shared';

import IEOActivateBg from '../../assets/crowdsale_bg.png';

const IeoActivateTitle = () => {
  const price = useShallowSelector(crowdsaleModel.selectors.getProp('cratPrice'));

  return (
    <Typography
      variant="h4"
      textTransform="uppercase"
      className="large semi-bold center"
      sx={{ span: { color: COLOR_ACCENT } }}
    >
      <span>Activate</span> IEO private placement <span>${fromDecimals(price)}</span>
    </Typography>
  );
};

export const IeoActivate = () => {
  const navigate = useNavigate();

  return (
    <Stack
      position="relative"
      overflow="hidden"
      mt={5}
      py={{ xs: 8, md: 9.25 }}
      border={`1px solid ${COLOR_STROKE_SECONDARY}`}
      borderRadius={BORDER_RADIUS_L}
      p={{ xs: 2, sm: 4 }}
      alignItems="center"
      spacing={4}
      sx={{
        backgroundImage: `url(${IEOActivateBg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <IeoActivateTitle />
      <Button
        onClick={() => navigate(appRoutes.preIeoPrivatePlacement.root.path)}
        sx={{ width: 170, fontWeight: 700, textTransform: 'uppercase', letterSpacing: '-0.03em' }}
      >
        Activate
      </Button>
    </Stack>
  );
};
