import { useEffect, useState } from 'react';
import { styled, Typography } from '@mui/material';
import { BORDER_RADIUS_L, BORDER_RADIUS_S, COLOR_ACCENT, COLOR_STROKE, FontWeights, TRANSITION_300 } from 'shared';

import { CELL_LABELS, getEndsIn, getUtcTimestamp } from '../../lib';

const Container = styled('div')(({ theme }) => ({
  marginInline: 'auto',
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 'fit-content',
}));

const Divider = styled('span')(({ theme }) => ({
  width: '25%',
  borderTop: `1px solid ${COLOR_STROKE}`,
}));

const Cell = styled('li')(({ theme }) => ({
  display: 'flex',
  width: '3.5rem',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: BORDER_RADIUS_L,
  border: `1px solid ${COLOR_STROKE}`,
  transition: TRANSITION_300,
  '&:hover': {
    borderColor: COLOR_ACCENT,
  },
  [theme.breakpoints.up('md')]: {
    width: '4rem',
  },
}));

const StyledList = styled('ul')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  marginTop: theme.spacing(1.5),
}));

const StyledSub = styled(Typography)(({ theme }) => ({
  border: `1px solid ${COLOR_STROKE}`,
  padding: theme.spacing(0.25, 1),
  fontWeight: FontWeights.SemiBold,
  marginTop: theme.spacing(1.5),
  borderRadius: BORDER_RADIUS_S,
}));

/** 30/nov/2024 23:59:59 */
const targetUtcTimestamp = Date.UTC(2024, 10, 30, 23, 59, 59);

const ONE_SECOND = 1000;

export const IcoCountdown = () => {
  const [endsIn, setEndsIn] = useState(Math.max(targetUtcTimestamp - getUtcTimestamp(), 0));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setEndsIn((prev) => {
        const newEndsIn = prev - ONE_SECOND;

        if (newEndsIn < 0) {
          clearInterval(intervalId);

          return 0;
        }

        return newEndsIn;
      });
    }, ONE_SECOND);

    return () => clearInterval(intervalId);
  }, []);

  const { parts, unit } = getEndsIn(endsIn);

  return (
    <Container>
      <Divider />
      <Typography mt={2} variant="h6" className="secondary" textTransform="uppercase" component="p">
        PRIVATE ROUND {unit[0]} {unit[1]} left
        <Typography variant="h4" component="span" color={COLOR_ACCENT} />
      </Typography>
      <StyledList>
        {Object.entries(parts).map(([key, value]) => (
          <Cell key={key}>
            <Typography variant="h4" className="large" lineHeight="1 !important">
              {value}
            </Typography>
            <Typography variant="h6" className="secondary" lineHeight="1 !important">
              {CELL_LABELS[key as keyof typeof CELL_LABELS]}
            </Typography>
          </Cell>
        ))}
      </StyledList>
      <StyledSub>Next up: IEO at $0.75 per CRAT</StyledSub>
    </Container>
  );
};
