import { IconButton, Stack, styled, Typography } from '@mui/material';
import { COLOR_STROKE, COLOR_WH, FontWeights, Icons } from 'shared';

import { useModalState } from './provider';

const CloseButton = styled(IconButton)(({ theme }) => ({
  background: 'none',
  marginLeft: 'auto',
  width: 48,
  height: 48,
  color: COLOR_WH,
  border: `1px solid ${COLOR_STROKE}`,
  borderRadius: '50%',
  svg: {
    width: 14,
    height: 14,
  },
  '&:hover': {
    background: 'none',
  },
}));

export const Header = () => {
  const { tab, onClose } = useModalState();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      {tab === 'form' && (
        <Typography variant="h4" fontWeight={FontWeights.SemiBold}>
          The Private Round is now open for a limited time before IEO @ $0.75 <br />
          BUY NOW for $0.28
        </Typography>
      )}
      <CloseButton onClick={onClose}>
        <Icons.Close />
      </CloseButton>
    </Stack>
  );
};
