import { useMemo } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { crowdsaleModel } from 'entities/crowdsale';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import {
  BORDER_RADIUS_M,
  COLOR_ACCENT,
  COLOR_BG_BLACK,
  COLOR_PRIMARY,
  COLOR_STROKE,
  FontFamilies,
  fromDecimals,
  IS_PRODUCTION,
  useShallowSelector,
} from 'shared';

const chartItems = [
  {
    price: '$0.28',
    destination: 16_963,
  },
  {
    price: '$0.29',
    destination: 100_000,
  },
  {
    price: '$0.30',
    destination: 500_000,
  },
  {
    price: '$0.75',
    destination: 3_600_000,
  },
];

// start estimation point from contract
const START_TOTAL_RAISED_USD = IS_PRODUCTION ? 783036.932471 : 222446;

export const AllocationProgress = () => {
  const cratPrice = useShallowSelector(crowdsaleModel.selectors.getProp('cratPrice'));
  const { usdTotalRaised } = useShallowSelector(crowdsaleModel.selectors.getCrowdsale);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formatBarLabel = (value: number) => {
    const prefix = '$';
    if (isMobile) {
      if (value < 1_000) return prefix + parseFloat(Number(value).toFixed(2));
      if (value < 1_000_000) return `${prefix}${parseFloat(Number(value / 1_000).toFixed(2))}K`;
      return `${prefix}${parseFloat(Number(value / 1_000_000).toFixed(2))}M`;
    }
    return `$${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(value)}`;
  };

  const formatYAxis = (value: number) => {
    if (value === 0) return '$0';
    return `$${value / 1_000_000}M`;
  };

  const getChartData = useMemo((): { price: string; value: number; destination: number }[] => {
    let totalRaised = new BigNumber(fromDecimals(usdTotalRaised)).minus(START_TOTAL_RAISED_USD);
    return chartItems.map(({ price, destination }, index) => {
      if (index === chartItems.length - 1) return { price, value: destination, destination: 0 };
      if (totalRaised.isGreaterThanOrEqualTo(destination)) {
        totalRaised = totalRaised.minus(destination);
        return { price, value: destination, destination: 0 };
      }
      const result = { price, value: totalRaised.toNumber(), destination: destination - totalRaised.toNumber() };
      totalRaised = new BigNumber('0');
      return result;
    });
  }, [usdTotalRaised]);

  return (
    <Box>
      <Stack
        mb={{ xs: 3, sm: 5.5 }}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">
          <Box component="span" color={COLOR_ACCENT}>
            Allocation Progress
          </Box>{' '}
          for $CRAT Coins
        </Typography>
        <Stack
          justifyContent="center"
          alignItems="center"
          width={{ xs: '100%', sm: 208 }}
          height={55}
          border={`1px solid ${COLOR_STROKE}`}
          borderRadius={BORDER_RADIUS_M}
        >
          <Typography variant="h4">
            Price:{' '}
            <Box component="span" color={COLOR_ACCENT}>
              ${fromDecimals(cratPrice)}
            </Box>
          </Typography>
        </Stack>
      </Stack>
      <ResponsiveContainer width="100%" height={586} style={{ fontFamily: FontFamilies.secondary }}>
        <BarChart data={getChartData} barGap={1}>
          <CartesianGrid vertical={false} strokeDasharray="3 3" strokeOpacity="0.5" />
          <XAxis dataKey="price" strokeDasharray={3} tick={{ fill: COLOR_PRIMARY }} />
          <YAxis type="number" domain={[0, 5_000_000]} tickCount={11} strokeOpacity={0} tickFormatter={formatYAxis} />
          <Bar
            dataKey="value"
            stackId="a"
            fill={COLOR_ACCENT}
            background={{ fill: '#FFFFFF0D' }}
            label={{
              position: 'center',
              fill: COLOR_BG_BLACK,
              fontWeight: 600,
              ...(isMobile && {
                width: 200,
                angle: -90,
              }),
              formatter: (v: number) => (v === 3_600_000 ? 'MAIN IEO STAGE' : ''),
            }}
          />
          <Bar
            dataKey="destination"
            stackId="a"
            opacity={0.5}
            fill="transparent"
            background={{ fill: '#FFFFFF0D' }}
            label={{
              position: 'top',
              formatter: formatBarLabel,
            }}
          />
        </BarChart>
      </ResponsiveContainer>
      <Typography variant="body2" textAlign="center">
        Price per Coin
      </Typography>
    </Box>
  );
};
