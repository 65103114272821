import { Box, Button, Stack, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_SECONDARY, COLOR_STROKE, links } from 'shared';
import { BuyCoins, UserStatistic } from 'widgets';

export const CrowdsalePanel = () => (
  <Box>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      display={{ xs: 'none', md: 'flex' }}
      mb={5.75}
    >
      <Typography variant="h4" className="primary">
        Price{' '}
        <Box component="span" color={COLOR_ACCENT}>
          per Coin
        </Box>
      </Typography>
      <Button
        variant="outlined"
        href={links.howToBuyGitBook}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          px: 2,
          width: 169,
          height: { xs: 40, md: 56 },
          color: COLOR_SECONDARY,
          border: `1px solid ${COLOR_STROKE}`,
          '&:hover': { border: `1px solid ${COLOR_ACCENT}` },
        }}
      >
        How To Buy?
      </Button>
    </Stack>
    <Stack direction={{ md: 'row', xs: 'column' }} gap={{ xs: 2, md: 4 }}>
      <Box
        sx={{
          flexGrow: 1,
          flexBasis: 0,
        }}
      >
        <BuyCoins />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          flexBasis: 0,
        }}
      >
        <UserStatistic />
      </Box>
    </Stack>
  </Box>
);
