export const dataRows = [
  {
    title: 'Early-Bird Pricing',
    description: 'Access $CRAT at special rates starting from $0.28.',
  },
  {
    title: 'Limited Allocation',
    description: 'Secure your position before the public IEO begins.',
  },
  {
    title: 'Strategic Advantage',
    description: 'Gain early access to the CratD2C ecosystem and its groundbreaking utilities.',
  },
  {
    title: 'Flexible Entry Tiers',
    description: 'Start small or big with multiple pricing options to suit your investment strategy.',
  },
  {
    title: 'Secure 268% Yield Ahead of the IEO:',
    description:
      'Seize the chance to secure a potential 268% return before the IEO kicks off at a starting price of $0.75.',
  },
];
