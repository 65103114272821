import { Stack } from '@mui/material';
import { useUpdateCrowdsaleData } from 'shared';

import { AllocationProgress, CrowdsalePanel, Intro, WhyParticipate } from './ui';

export const PreIeoPrivatePlacement = () => {
  useUpdateCrowdsaleData();

  return (
    <Stack gap={{ xs: 8, md: 9 }} mb={{ xs: 5, md: 15.75 }}>
      <Intro />
      <WhyParticipate />
      <AllocationProgress />
      <CrowdsalePanel />
    </Stack>
  );
};
