import {
  BeInCrypto,
  Benzinga,
  BitcoinCom,
  Bitcoinist,
  CertikLogo,
  CoinstoreLogo,
  Cointelegraph,
  Crypronews,
  CryptoPotato,
  Disrupt,
  FasterCapitalLogo,
  HackenLogo,
  IntellectLawyerLogo,
  InterocoLogo,
  JoorneyLogo,
  LbankLogo,
  LondonRateLogo,
  MexcLogo,
  NewsBtc,
  Probit,
  RnbLogo,
  TechBullion,
  Techopedia,
  Techpoint,
  YahooFinance,
} from 'shared';

export const news = [
  { id: 1, image: YahooFinance },
  { id: 2, image: Benzinga },
  { id: 3, image: BitcoinCom },
  { id: 4, image: Bitcoinist },
  { id: 5, image: Cointelegraph },
  { id: 6, image: Crypronews },
  { id: 7, image: CryptoPotato },
  { id: 8, image: NewsBtc },
  { id: 9, image: Disrupt, maxHeight: 67 },
  { id: 10, image: TechBullion },
  { id: 11, image: Techopedia },
  { id: 12, image: Techpoint },
  { id: 13, image: BeInCrypto },
];

export const partners = [
  { id: 1, subtitle: 'Official Partners', image: RnbLogo, maxHeight: 25 },
  { id: 2, image: JoorneyLogo, maxHeight: 41 },
  { id: 3, url: 'https://skynet.certik.com/projects/cratd2c', subtitle: 'Audit', image: CertikLogo, maxHeight: 36 },
  { id: 4, url: 'https://audits.hacken.io/cratd2c', image: HackenLogo, maxHeight: 28 },
  { id: 5, subtitle: 'Backers', image: FasterCapitalLogo, maxHeight: 26 },
  { id: 6, subtitle: 'IP Certification', image: InterocoLogo, maxHeight: 55 },
  { id: 7, url: 'https://www.mexc.com', subtitle: 'Pre-confirmed listing', image: MexcLogo, maxHeight: 21 },
  { id: 8, url: 'https://www.lbank.com', image: LbankLogo, maxHeight: 28 },
  { id: 9, url: 'https://www.coinstore.com', image: CoinstoreLogo, maxHeight: 38 },
  { id: 10, url: 'https://www.probit.com', image: Probit, maxHeight: 28 },
  { id: 11, subtitle: 'Valuation', image: LondonRateLogo, maxHeight: 65 },
  { id: 12, subtitle: 'Legal', image: IntellectLawyerLogo, maxHeight: 47 },
];
