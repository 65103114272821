import { Box, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { useBreakpoints } from 'shared';

export const Intro = () => {
  const { isDownSmLayout } = useBreakpoints();

  return (
    <Box>
      <Typography
        component="h2"
        variant="h1"
        sx={{
          maxWidth: '1050px',
        }}
      >
        <Typography variant="inherit" fontWeight="inherit" component="span" className="accent">
          Pre-IEO Private
        </Typography>{' '}
        Placement
      </Typography>
      <Grid container justifyContent="end" mt={{ xs: 2, md: '50px' }}>
        <Grid item xs={12} md={8}>
          <Typography
            variant={isDownSmLayout ? 'body2' : 'h5'}
            fontSize={{ xs: 14, md: 26 }}
            className={clsx('no-resize', isDownSmLayout && 'font-secondary secondary')}
            maxWidth={727}
          >
            Take advantage of this exclusive opportunity to secure $CRAT coins before the official IEO launch. This is
            your chance to invest at preferential rates and be part of a revolutionary blockchain ecosystem.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
