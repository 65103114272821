import { Box, Button, Stack, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_SECONDARY, COLOR_STROKE, links, useUpdateCrowdsaleData } from 'shared';
import { BuyCoins, UserStatistic } from 'widgets';

import { CrowdsaleProgress, IeoActivate, Price } from './ui';

export const Crowdsale = () => {
  useUpdateCrowdsaleData();

  return (
    <Box component="section" mt={{ sm: 8, xs: 12 }} id="crowdsale">
      <Stack direction="row" justifyContent="space-between" alignContent="center">
        <Typography variant="h4" className="secondary" sx={{ pt: { xs: 0.8, md: 1.5 } }}>
          Crowdsale
        </Typography>
        <Button
          variant="outlined"
          href={links.howToBuyGitBook}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            px: 2,
            width: 169,
            height: { xs: 40, md: 56 },
            color: COLOR_SECONDARY,
            border: `1px solid ${COLOR_STROKE}`,
            '&:hover': { border: `1px solid ${COLOR_ACCENT}` },
          }}
        >
          How To Buy?
        </Button>
      </Stack>
      <CrowdsaleProgress />
      <IeoActivate />
      <Price />
      <Stack direction={{ md: 'row', xs: 'column-reverse' }} gap={4} mt={3.5}>
        <Box
          sx={{
            flexGrow: 1,
            flexBasis: 0,
          }}
        >
          <BuyCoins />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            flexBasis: 0,
          }}
        >
          <UserStatistic />
        </Box>
      </Stack>
    </Box>
  );
};
